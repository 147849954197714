/*
 * [mutateCookieHeaders]
 * Mutates request object's headers.cookie value
 * Converts currency cookie from object {"isoCode":"USD","symbol":"$",...} to isoCode only
 * Needed for requests (e.g. /orders) that expect the currency cookie to provide a currency code
 */
export function mutateCookieHeaders(req: Request) {
  if (req?.cookies?.currency && req?.headers?.cookie) {
    const currencyCode = (() => {
      try {
        const currencyCookie = JSON.parse(req.cookies.currency)
        return currencyCookie?.isoCode
      } catch {
        return null
      }
    })()

    if (currencyCode) {
      const cookies = req?.headers?.cookie?.split('; ') || []
      const currencyCookieIndex = cookies.findIndex((cookie) => cookie.includes('currency='))
      if (currencyCookieIndex >= 0) {
        cookies[currencyCookieIndex] = `currency=${currencyCode}`
        req.headers.cookie = cookies.join('; ')
      }
    }
  }
}
