import { Response } from 'express'
import getConfig from 'next/config'

// Sets the auth jwt cookie with appropriate cookie settings
const setSecureCookie = (authToken: string, res: Response) => {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()
  const cookieOptions = {
    httpOnly: true,
    secure: serverRuntimeConfig.releaseEnv === 'production' ? true : false,
  }
  return res.cookie(publicRuntimeConfig.accessTokenKey, authToken, cookieOptions)
}

export default setSecureCookie
