import { GetServerSidePropsContext } from 'next'

import { NormalizedCacheObject } from '@apollo/client'
import { CMSBannerData } from 'banner/Banner'
import { Request, Response } from 'express'
import { getCanonicalUrl } from 'head/utils/seoHelpers'
import { serverRequestService } from 'server/services/serverRequestService'
import { logger } from 'server/utils/logger'
import createApolloClient from 'shared/apollo/createApolloClient'
import { sizes } from 'shared/lib/media'
import { parse } from 'shared/lib/safeJSON'
import { Breadcrumb } from 'shared/types/Breadcrumb'
import { Country } from 'shared/types/Country'
import { ICurrency } from 'shared/types/ICurrency'
import { ShoppingRegionCountry } from 'shared/types/RegionPreference'
import { sortBreadcrumbChildren } from 'shared/utils/breadcrumb-utils'

export type FCGetServerSidePropsContext = GetServerSidePropsContext & {
  req: Request & {
    /** Banner object set in the server request object by the bannerInjector middleware */
    cmsBanner: string
    /** Feature Flags object set in the server request object by the feature flags middleware */
    featureFlags: Record<string, boolean>
    /** Available Currencies set on server request object by currency middleware */
    availableCurrencies: ICurrency[]
    /** Shopping region object set in the server request object by the region preference middleware. */
    shoppingRegionCountry: ShoppingRegionCountry
  }
  res: Response
}

export type GetServerPageProps = {
  /** Used by withApolloClient HOC. TODO: Check use and need.  */
  apolloState: NormalizedCacheObject
  /** Array of available countries, i.e. Country.shipsTo is true. */
  availableCountries: Country[]
  /** Array of currencies */
  availableCurrencies: ICurrency[]
  /** Canonical URL for current page */
  canonicalUrl: string
  /** Banner object set in the server request object by the bannerInjector middleware */
  cmsBanner: CMSBannerData
  /** Used by withApolloClient HOC. TODO: Check use and need. */
  csrf: string | null
  /** Used by withApolloClient HOC. TODO: Check use and need. */
  currency: ICurrency
  /** Normalized version of the request URL that strips the _next/data prefix
   * for client transitions and includes original query values. */
  currentPath: string
  /** Has auth cookie  */
  isAuthenticated: boolean
  /** Feature Flags object set in the server request object by the feature flags middleware */
  featureFlags: Record<string, boolean>
  /** Footer object  */
  footerData: Breadcrumb
  /** Active locale */
  locale: string
  /** Shopping region object for the ShoppingRegionContextProvider */
  shoppingRegionCountry: ShoppingRegionCountry
  /** SSR width used to determine the initial breakpoint for the responsive design */
  initWidth: number
}

/**
 * This function is intended to be used in all Pages that fetch data from the server.
 * It returns the props used in the custom App component.
 * @param context  - augemented Next.js GetServerSidePropsContext
 */
export const getServerPageProps = async (
  context: FCGetServerSidePropsContext,
): Promise<GetServerPageProps> => {
  const { req, res, resolvedUrl, locale, defaultLocale, locales } = context
  const activeLocale = locale || defaultLocale || 'en'
  const {
    cmsBanner: reqCmsBanner,
    cookies,
    featureFlags,
    originalUrl,
    availableCurrencies,
    shoppingRegionCountry,
  } = req
  const token: string = cookies.jwt
  const csrf = req.csrfToken ? req.csrfToken() : null
  const currency: ICurrency = parse(cookies?.currency) || res?.locals?.currency || null
  const cmsBanner = parse(reqCmsBanner) as CMSBannerData
  const canonicalUrl = getCanonicalUrl(
    req,
    resolvedUrl,
    originalUrl,
    activeLocale,
    defaultLocale || '',
    locales || [],
  )

  const isAuthenticated = !!cookies?.jwt

  let availableCountries: Country[] = []
  let footerData: Breadcrumb = { children: [], name: '', slug: '' }

  // Fetch countries
  try {
    const { data } = await serverRequestService(req, res, 'sneakers').get<{ countries: Country[] }>(
      '/api/v1/countries',
    )
    availableCountries = data.countries.filter((country) => country.shipsTo) || []
  } catch (error) {
    logger.error(error, 'Error fetching countries.')
  }

  // Fetch footer breadcrumb data
  try {
    const { data } = await serverRequestService(req, res, 'sneakers').get(
      '/api/v1/breadcrumbs/footer?includeChildren=true',
    )
    footerData = sortBreadcrumbChildren(data)
  } catch (error) {
    logger.error(error, 'Error fetching footer data.')
  }

  // Set width when SSR
  // Mobile automation sends a is_mobile cookie so that we can force mobile sizing when SSR
  const isMobileAutomation = cookies.is_mobile ?? false
  const initWidth = !isMobileAutomation ? 0 : sizes.extraSmall
  const apolloState = getApolloClientState(context, token, csrf, currency)

  return {
    apolloState,
    availableCountries,
    availableCurrencies,
    canonicalUrl,
    cmsBanner,
    csrf,
    currency,
    currentPath: resolvedUrl,
    isAuthenticated,
    featureFlags,
    footerData,
    locale: activeLocale,
    shoppingRegionCountry,
    initWidth,
  }
}

/**
 * Used by withApolloClient HOC. TODO: Check use and need.
 */
const getApolloClientState = (
  context: FCGetServerSidePropsContext,
  token: string,
  csrf: string | null,
  currency: ICurrency,
) => {
  const { req, res } = context
  const getCsrf = () => csrf
  const getToken = () => token
  const apollo = createApolloClient(
    {},
    {
      getCsrf,
      getToken,
      req,
      res,
      currency,
    },
  )

  // Extract query data from the Apollo store
  const apolloState: NormalizedCacheObject = apollo.cache.extract()

  return apolloState
}
