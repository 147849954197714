import { Request } from 'express'
import { IncomingHttpHeaders } from 'http'
import camelCase from 'lodash/camelCase'
import cloneDeep from 'lodash/cloneDeep'
import upperFirst from 'lodash/upperFirst'
import getConfig from 'next/config'
import { getStandardLocaleCode } from './localization'

type RequestWithCookies = Request & {
  cookies?: {
    [key: string]: string
  }
}

type PartialHeaders = IncomingHttpHeaders & {
  'x-forwarded-user-agent'?: string
  'x-goatapp-version'?: string
  'user-agent'?: string
}

// Mutates the header object for a Request, adding our special 'GOATWebFc' user agent string
// and copying the original user agent to `x-forwarded-user-agent`
export function appendUserAgentString(req: RequestWithCookies) {
  const headers: PartialHeaders = cloneDeep(req.headers)

  // Prevent double-appending, in case this logic runs again elsehwere in the middleware stack
  if (headers?.['user-agent'] && /webfc/i.test(headers['user-agent'])) {
    return headers
  }

  const {
    serverRuntimeConfig: { appName: APP_NAME },
    publicRuntimeConfig: { appVersion: APP_VERSION, releaseEnv: RELEASE_ENV },
  } = getConfig()

  const appName: string = upperFirst(camelCase(APP_NAME)) // kabob case to pascal case
  const releaseEnv: string = upperFirst(RELEASE_ENV)
  const version: string = headers['x-goatapp-version'] || APP_VERSION
  const clientIdentifier = `GOAT${appName}${releaseEnv}/${version}`
  const originalUserAgent = `${headers['user-agent'] || ''}`

  const localeCode = req?.cookies?.locale
  const localeUserAgent = localeCode ? ` Locale/${getStandardLocaleCode(localeCode)}` : ''

  headers['x-forwarded-user-agent'] = originalUserAgent
  headers['user-agent'] = `${originalUserAgent} ${clientIdentifier}${localeUserAgent}`
  return headers
}
