import omit from 'lodash/omit'

export function prepareRequestHeaders(headers = {}) {
  return omit(headers, [
    'accept',
    'connection',
    'content-encoding',
    'content-length',
    'host',
    'referer',
    'upgrade-insecure-requests',
    'x-forwarded-proto',
  ])
}
