import getConfig from 'next/config'
import jwt from 'jsonwebtoken'

const createAuthToken = user => {
  const { serverRuntimeConfig } = getConfig()
  const payload = {
    authToken: user.authToken,
    username: user.username,
  }
  const options = {
    subject: `${user.id}`,
  }
  return jwt.sign(payload, serverRuntimeConfig.jwtSecret, options)
}

export default createAuthToken
