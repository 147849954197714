import { Request } from 'express'
import translationEN from '../../../public/locales/en/common.json'
import translationENJP from '../../../public/locales/en-jp/common.json'
import translationJA from '../../../public/locales/ja/common.json'

export const DEFAULT_LANGUAGE = 'en'

export interface ILanguageNavConfig {
  [language: string]: {
    label: string
    sneakerCode: string
    localeCode: string
    translation: object
    dateFormat?: Intl.DateTimeFormatOptions
    dateTimeFormat?: Intl.DateTimeFormatOptions
  }
}

export const LANGUAGES: ILanguageNavConfig = {
  en: {
    label: 'English',
    sneakerCode: 'en',
    localeCode: 'en-US',
    translation: translationEN,
    dateFormat: {
      timeZone: 'America/New_York',
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
    },
    dateTimeFormat: {
      timeZone: 'America/New_York',
      timeZoneName: 'short',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'en-jp': {
    label: 'English',
    sneakerCode: 'en',
    localeCode: 'en-US',
    translation: translationENJP,
    dateFormat: {
      timeZone: 'America/New_York',
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
    },
    dateTimeFormat: {
      timeZone: 'America/New_York',
      timeZoneName: 'short',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  ja: {
    label: '日文',
    sneakerCode: 'ja',
    localeCode: 'ja-JP',
    translation: translationJA,
    dateFormat: {
      timeZone: 'Asia/Tokyo',
      dateStyle: 'long',
    },
    dateTimeFormat: {
      timeZone: 'Asia/Tokyo',
      timeZoneName: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
}

export const i18nConfig = {
  resources: {
    en: {
      translation: translationEN,
    },
    'en-jp': {
      translation: translationENJP,
    },
    ja: {
      translation: translationJA,
    },
  },
  lng: DEFAULT_LANGUAGE,
  fallbackLng: {
    default: ['en'],
    'ja-jp': ['ja'],
    jp: ['ja'],
  },
  debug: false,
  lowerCaseLng: true,
}

// if our locale code does not match the standard locale code,
// we can exchange here
// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
// https://stripe.com/docs/js/appendix/supported_locales
export const getStandardLocaleCode = (locale?: string): string => {
  switch (locale) {
    case 'jp':
    case 'ja-jp':
      return 'ja'
    case 'en-jp':
    case null:
    case undefined:
    case '':
      return 'en'
    default:
      return locale
  }
}

// server side translation
export const st = (key: string, options: any) => {
  const { req } = options
  if (typeof options === 'string') {
    return req?.t(key, {
      defaultValue: options,
      lng: req?.cookies?.locale,
      lngs: [req?.cookies?.locale],
    })
  }
  return req?.t(key, {
    ...options,
    lng: getStandardLocaleCode(req?.cookies?.locale),
    lngs: [getStandardLocaleCode(req?.cookies?.locale)],
  })
}

export const getLocaleDateTime = (utcDate: string, req: Request) => {
  const locale = req?.cookies?.locale || DEFAULT_LANGUAGE
  const localeData = LANGUAGES?.[getStandardLocaleCode(locale)]
  const { dateTimeFormat, localeCode } = localeData
  const date = new Date(utcDate)

  return new Intl.DateTimeFormat(localeCode, dateTimeFormat).format(date)
}

export const getLocalDate = (utcDate: string, req: Request) => {
  const locale = req?.cookies?.locale || DEFAULT_LANGUAGE
  const localeData = LANGUAGES?.[getStandardLocaleCode(locale)]
  const { dateFormat, localeCode } = localeData
  const date = new Date(utcDate)

  return new Intl.DateTimeFormat(localeCode, dateFormat).format(date)
}
