import axios, { AxiosError } from 'axios'
import { Request, Response } from 'express'
import cloneDeep from 'lodash/cloneDeep'
import getConfig from 'next/config'

import { appendUserAgentString } from 'server/utils/appendUserAgentString'
import { getUser } from 'server/utils/auth'
import { logger } from 'server/utils/logger'
import { mutateCookieHeaders } from 'server/utils/mutateCookieHeaders'
import { prepareRequestHeaders } from 'server/utils/prepareRequestHeaders'
import { appHeaders } from 'shared/services/appHeaders'
import { App } from 'shared/types/App'

/**
 * [serverRequestService]
 * Returns an Axios instance with a predefined custom config for making
 * server-side requests to GOAT apps
 *
 * Axios Instance Reference: https://axios-http.com/docs/instance
 *
 * Usage:
 *   serverRequestService(req, res, 'sneakers').get('/api/v1/foo')
 *   serverRequestService(req, res, 'sneakers').post('/api/v1/foo', {hello: 'world'})
 *   serverRequestService(req, res, 'cms').delete('/api/v1/foo/1')
 *   serverRequestService(req, res, 'cms').put('/api/v1/foo', {hello: 'world'})
 */
export const serverRequestService = (
  req: Request,
  res: Response,
  app: App = 'sneakers',
  cacheControl: string = '',
) => {
  const maxUrlDisplay = 250

  const {
    publicRuntimeConfig: { accessTokenKey },
    serverRuntimeConfig: { apiUrl },
  } = getConfig()

  // Copy req object and update its headers.cookie value. Use mutated request in Axios instance to
  // avoid changing NextPageContext req used in getInitialProps/getServerSideProps/getStaticProps
  const request = cloneDeep(req)
  mutateCookieHeaders(request)

  const defaultHeaders = {
    ...appendUserAgentString(request),
    ...(cacheControl && { 'cache-control': cacheControl }),
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-goat-sales-channel': '2',
  }

  const authCookie = request.cookies?.[accessTokenKey]
  const authToken = getUser(authCookie)?.authToken || ''
  const instance = axios.create({
    baseURL: apiUrl,
    headers: prepareRequestHeaders({
      ...request.headers,
      ...defaultHeaders,
      ...appHeaders({ app, isClient: false, token: authToken }),
    }),
    withCredentials: true,
  })

  // Interceptors (Logging)
  instance.interceptors.request.use(
    (config) => {
      const url = `${config.baseURL}${config.url?.slice(0, maxUrlDisplay) || ''}`
      logger.info(`${app} REQ >>> ${config.method?.toUpperCase()} ${url}`)
      return config
    },
    (error) => {
      logger.error(error, `${app} req error`)
      return Promise.reject(error)
    },
  )

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      const url = `${response.config.baseURL}${response.config.url}`
      const displayUrl =
        url?.length || 0 > maxUrlDisplay ? `${url?.slice(0, maxUrlDisplay)}...` : url
      logger.info(`${app} RES ${response.status} <<< ${displayUrl}}`)
      if (process.env.NODE_ENV !== 'production') {
        // logger.info(response, '<<< response')
      }
      return response
    },
    (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      logger.error(
        {
          message: error.message,
          code: error.code,
          url: `${error.config.baseURL}${error.config.url}`,
        },

        `[serverRequestService] ${app} service response error.`,
      )
      return Promise.reject(error)
    },
  )

  return instance
}
