/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import Head from 'next/head'
import { SearchResults } from 'react-instantsearch-core'
import { findResultsState } from 'react-instantsearch-dom/server'
import { AlgoliaIndex } from 'search'
import styled from 'styled-components'

import Bugsnag from '@bugsnag/js'
import ShrinkWrappedJordan1Bred from 'notFound/ShrinkWrappedJordan1Bred'
import AlgoliaSearchClient from 'search/components/AlgoliaSearchClient'
import PageLevelInstantSearch from 'search/components/PageLevelInstantSearch'
import TrendingProducts from 'search/components/TrendingProducts'
import { ErrorPagesQA } from 'shared/dataAttributes'
import { colors, fonts, media, styles } from 'shared/lib'
import { getConsumerSearchFilters, ISearchState } from 'shared/utils/algolia-utils'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { getServerPageProps } from 'shared/utils/getServerSideProps'
import { serverSideConfigs } from 'localization'
import { toAlgoliaProductHit } from 'layout/navigation/search/utils'
import { useCallback, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { STORAGE } from 'shared/enums/SitePreferences'

interface IErrorStatusProps {
  header: string
  subtitle: string
  title: string
}

const statusInfo = new Map<number, IErrorStatusProps>([
  [
    404,
    {
      title: '404 Not Found',
      header: '404 - Page Not Found',
      subtitle:
        'Sorry, we could not find this page. Please search again or navigate to the home page to find what you are looking for.',
    },
  ],
  [
    500,
    {
      title: '500 Internal Server Error',
      header: 'Something went wrong.',
      subtitle:
        "An error has occurred and we're working to fix it. Please try again in a few minutes",
    },
  ],
])

interface IErrorProps {
  statusCode?: number
  trendingResultsState?: SearchResults
}

const Error = ({ statusCode, trendingResultsState }: IErrorProps) => {
  const errorInfo = (statusCode && statusInfo.get(statusCode)) || statusInfo.get(404)
  const { header, subtitle, title } = errorInfo

  const isConsumerSearchEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_CONSUMER_SEARCH)

  const [consumerSearchTrendingSearch, setConsumerSearchTrendingSearch] = useState([])

  const getConsumerSearchTrendingSearch = useCallback(async () => {
    const consumerSearchTrendingSearchQuery = getConsumerSearchFilters({
      searchState: {} as ISearchState,
      cookies: {
        currency: Cookies.get(STORAGE.CURRENCY) as string,
        country: Cookies.get(STORAGE.COUNTRY) as string,
      },
      queryParams: { sortBy: 'trending' },
      pageLimit: '8',
    })
    const url =
      '/api/product-search?' +
      new URLSearchParams(consumerSearchTrendingSearchQuery as any).toString()
    const { data } = await fetch(url, {
      method: 'GET',
    })
      .then(async (res) => {
        return res.json()
      })
      .catch(() => {
        return {
          data: {},
          error: 'error fetching data',
          total: 0,
        }
      })
    setConsumerSearchTrendingSearch(toAlgoliaProductHit(data?.productsList || []))
  }, [])

  useEffect(() => {
    if (isConsumerSearchEnabled) {
      void getConsumerSearchTrendingSearch()
    }
  }, [isConsumerSearchEnabled])

  return (
    <>
      <Head>
        <title>{title} | Flight Club</title>
      </Head>
      <TopBanner>
        <BannerMessage>
          <Header data-qa={ErrorPagesQA.ErrorPageSectionTitle}>{header}</Header>
          <Subtitle data-qa={ErrorPagesQA.ErrorPageSectionSubTitle}>{subtitle}</Subtitle>
        </BannerMessage>
        <SneakerImage />
      </TopBanner>
      {isConsumerSearchEnabled ? (
        <TrendingProducts hits={consumerSearchTrendingSearch} isAlgolia={false} />
      ) : (
        <TrendingProducts resultsState={trendingResultsState!} />
      )}
    </>
  )
}

Error.getInitialProps = async (context: any) => {
  const { res, locale, err } = context

  if (err) {
    // Must be created within getInitialProps because this relies on runtime configs.  If invoked
    // outside of getInitialProps or componentDidMount, the project will try to create a bugsnag
    // client when building
    Bugsnag.notify(err)
  }

  const trendingResultsState = await findResultsState(PageLevelInstantSearch, {
    indexName: AlgoliaIndex.Trending,
    searchClient: AlgoliaSearchClient,
  })

  const statusCode = res ? res.statusCode : err ? err.statusCode : 404

  return {
    props: {
      ...(await getServerPageProps(context)),
      ...(await serverSideConfigs(res, locale)),
      statusCode,
      trendingResultsState: JSON.parse(JSON.stringify(trendingResultsState)),
    },
  }
}

const TopBanner = styled.div`
  background-color: ${colors.FC2_WHITE};
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  padding: ${styles.mobilePageLayoutPadding};
`

const BannerMessage = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 40px;
`

const Header = styled.h1`
  ${fonts.HEADER_1};
  font-weight: 500;
`

const Subtitle = styled.h2`
  ${fonts.BODY_TEXT};
  font-weight: unset;
`

const SneakerImage = styled(ShrinkWrappedJordan1Bred)`
  ${media.large`
    margin: 50px 20px;
  `}
`

export default Error
