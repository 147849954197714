import getConfig from 'next/config'
import jwt from 'jsonwebtoken'

export interface User {
  sub?: number
  authToken?: string
  username?: string
}

const getUser = (token: string): User | null => {
  const { serverRuntimeConfig } = getConfig()

  return token ? (jwt.verify(token, serverRuntimeConfig.jwtSecret) as User) : null
}

export default getUser
